.marginTen {
    margin: 10px;
}

.marginFive {
    margin: 5px;
}

.selectWidth {
    width: 60%;
    align-self: center;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fileUploadContainer {
    border: 2px dashed #eeeeee;
    padding: 15px;
    border-radius: 2px;
    background-color: #fafafa;
    color: #8a8585;
    margin: 20px;
}

.divCenter {
    width: 50%;
    margin: auto;
}

.strike {
    text-decoration: line-through;
}

.tableFormHeader {
    margin: 5px 5px 10px 12px;
    font-size: 14pt;
    font-weight: bold;
    text-align: left;
}

.display-linebreak {
    white-space: pre-line;
}